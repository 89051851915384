import React, { useContext, useState, useEffect } from 'react';
import InputMask from 'react-input-mask'
import { navigate, Link } from 'gatsby'
import { Context as LeaveContext } from '../components/LeaveContext'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivateRoute from '../components/PrivateRoute'
import LeaveRequestQuickNav from '../components/LeaveRequestQuickNav'
import getAnimationStyle from '../utils/getAnimationStyle'

export default function New_Leave_Request_step3() {
  const { Contact, stepThree } = useContext(LeaveContext)

  const [state, setState] = useState({
    PreferedPhone: '',
    PreferedPhoneList: [],
    OtherPhone: '',
    IsAcceptTextMessage: '',
    AcceptEmail: '',
    AcceptEmailList: [],
    OtherEmail: '',
    ConfirmOtherEmail: '',
    PreferCommunicationTypeList: [],
    PreferCommunicationTypeID: '',
    Error: {},
  })

  useEffect(() => {
    if (!Contact) {
      navigate('/Overview/');
    } else {
      setState(prev => ({
        ...prev,
        PreferedPhone: Contact.PreferedPhone === '' ? Contact.PreferedPhoneList.length > 0 ? Contact.PreferedPhoneList[0].Value : 'Other' : Contact.PreferedPhone,
        PreferedPhoneList: Contact.PreferedPhoneList,
        OtherPhone: Contact.OtherPhone ? Contact.OtherPhone : '',
        IsAcceptTextMessage: Contact.IsAcceptTextMessage ? Contact.IsAcceptTextMessage : '',
        AcceptEmail: Contact.AcceptEmail === '' ? Contact.AcceptEmailList.length > 0 ? Contact.AcceptEmailList[0].Value : 'Other' : Contact.AcceptEmail,
        AcceptEmailList: Contact.AcceptEmailList,
        OtherEmail: Contact.OtherEmail ? Contact.OtherEmail : '',
        ConfirmOtherEmail: Contact.ConfirmOtherEmail ? Contact.ConfirmOtherEmail : '',
        PreferCommunicationTypeList: Contact.PreferCommunicationTypeList,
        PreferCommunicationTypeID: Contact.PreferCommunicationTypeID === null ? '' : Contact.PreferCommunicationTypeID,
        Error: {},
      }))
    }
  }, [Contact])

  const onSubmit = (event) => {
    event.preventDefault()

    const Errors = {}
    // Validate Data
    let re = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/
    if (state.PreferedPhone === 'Other' && !re.test(String(state.OtherPhone).toLowerCase())) Errors.OtherPhone = 'New Phone is not valid'

    if (state.IsAcceptTextMessage !== 'Yes' && state.IsAcceptTextMessage !== 'No') Errors.IsAcceptTextMessage = 'Text Message is not valid'

    re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (state.OtherEmail === 'Other' && !re.test(String(state.OtherEmail).toLowerCase())) Errors.OtherEmail = 'New Email is not valid'

    if (state.ConfirmOtherEmail !== state.OtherEmail) Errors.ConfirmOtherEmail = 'Emails do not match'

    if (state.PreferCommunicationTypeID === '') Errors.PreferCommunicationTypeID = 'Prefered Communication is not valid'

    if (Object.keys(Errors).length > 0) {
      setState(prev => ({ ...prev, Error: Errors }))
      return false
    }

    // Remove errors before saving to store
    const newContactData = { ...state }
    delete newContactData.Error

    // Save to store
    stepThree(newContactData)
    navigate('/New_Leave_Request_step4/');
  }
  const handlePreferedPhoneChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.PreferedPhone
    if (e.target.value !== 'Other') {
      delete Error.OtherPhone
      setState(prev => ({
        ...prev, PreferedPhone: e.target.value, Error, OtherPhone: '',
      }))
      return
    }
    setState(prev => ({ ...prev, PreferedPhone: e.target.value, Error }))
  }
  const handleOtherPhoneChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.OtherPhone
    setState(prev => ({ ...prev, OtherPhone: e.target.value, Error }))
  }
  const handleIsAcceptTextMessageChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.IsAcceptTextMessage
    setState(prev => ({ ...prev, IsAcceptTextMessage: e.target.value, Error }))
  }
  const handlePreferCommunicationTypeIDChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.PreferCommunicationTypeID
    let PreferCommunicationTypeName = ''
    const SelectedCommunication = state.PreferCommunicationTypeList.filter(comms => comms.Value === e.target.value)
    if (SelectedCommunication.length > 0) PreferCommunicationTypeName = SelectedCommunication[0].Name
    setState(prev => ({
      ...prev,
      PreferCommunicationTypeID: e.target.value,
      Error,
      PreferCommunicationTypeName,
    }))
  }
  const handleAcceptEmailChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.AcceptEmail
    if (e.target.value !== 'Other') {
      delete Error.ConfirmOtherEmail
      setState(prev => ({
        ...prev, AcceptEmail: e.target.value, Error, OtherEmail: '', ConfirmOtherEmail: '',
      }))
      return
    }
    setState(prev => ({ ...prev, AcceptEmail: e.target.value, Error }))
  }
  const handleOtherEmailChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.OtherEmail
    setState(prev => ({ ...prev, OtherEmail: e.target.value, Error }))
  }
  const handleConfirmOtherEmailChange = (e) => {
    e.persist()
    const { Error } = state
    delete Error.ConfirmOtherEmail
    setState(prev => ({ ...prev, ConfirmOtherEmail: e.target.value, Error }))
  }
  return (
    <PrivateRoute>
      <Layout activateLink>
        <SEO title="Leave Request Step 3" />
        <div className="content-outline-998 leaveForm">
          <LeaveRequestQuickNav />
          <p>&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <div className="questionhdr">
            How would you like us to contact you?<br />
          </div>
          <form
            className="LeaveFormEntry"
            onSubmit={onSubmit}
          >
            <div className={state.Error.PreferedPhone ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              <label htmlFor="ctl00_Holder_Request01_PrefNum">My preferred phone number is&nbsp;&nbsp;</label>
              <select
                id="ctl00_Holder_Request01_PrefNum"
                name="ctl00$Holder$Request01$PrefNum"
                style={{ width: '7.5em' }}
                value={state.PreferedPhone}
                onChange={handlePreferedPhoneChange}
              >
                {state.PreferedPhoneList.map(phone => <option key={phone.Name} value={phone.Value}>{phone.Name}</option>)}
                <option value="Other">Other</option>
              </select>
            </div>
            <br className="clear" />
            {state.PreferedPhone === 'Other' && (
            <div className={state.Error.OtherPhone ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
                Please specify this new phone number:&nbsp;&nbsp;
              <InputMask
                mask="(999) 999-9999"
                maskChar=" "
                alwaysShowMask
                type="tel"
                name="ctl00_Holder_Request01_NewPrefPhone"
                id="ctl00_Holder_Request01_NewPrefPhone"
                style={{ width: '7.5em' }}
                value={state.OtherPhone}
                onChange={handleOtherPhoneChange}
              />
              <br className="clear" />
            </div>
            )}
            <div className={state.Error.IsAcceptTextMessage ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(1)}>
              <label>
                Would you like text message notifications on this number?&nbsp;&nbsp;
              </label>
              <select
                name="ctl00$Holder$Request01$ddlTextNotify"
                id="ctl00_Holder_Request01_ddlTextNotify"
                style={{ width: '2.25em' }}
                value={state.IsAcceptTextMessage}
                onChange={handleIsAcceptTextMessageChange}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <br className="clear" />
            <div className={state.Error.AcceptEmail ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(2)}>
              <label>My Personal email is&nbsp;&nbsp;</label>
              <select
                name="ctl00$Holder$Request01$ddlLeaveEmail"
                id="ctl00_Holder_Request01_ddlLeaveEmail"
                style={{ width: '16em' }}
                value={state.AcceptEmail}
                onChange={handleAcceptEmailChange}
              >
                {state.AcceptEmailList.map(email => <option key={email.Name} value={email.Type}>{email.Name}</option>)}
                <option value="Other">Other</option>
              </select>
            </div>
            <br className="clear" />
            {state.AcceptEmail === 'Other' && (
            <div className={state.Error.OtherEmail ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              <label>Please specify this new email:&nbsp;&nbsp;</label>
              <input
                type="email"
                name="ctl00$Holder$Request01$NewLeaveEmail"
                id="ctl00_Holder_Request01_NewLeaveEmail"
                style={{ width: '16em' }}
                value={state.OtherEmail}
                onChange={handleOtherEmailChange}
              />
              <br className="clear" />
            </div>
            )}
            {state.AcceptEmail === 'Other' && (
            <div className={state.Error.ConfirmOtherEmail ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(1)}>
              <label>Please confirm new email:&nbsp;&nbsp;</label>
              <input
                type="email"
                name="ctl00$Holder$Request01$NewLeaveEmailConfirm"
                id="ctl00_Holder_Request01_NewLeaveEmailConfirm"
                style={{ width: '16em' }}
                value={state.ConfirmOtherEmail}
                onChange={handleConfirmOtherEmailChange}
              />
              <br className="clear" />
            </div>
            )}
            <div className={state.Error.PreferCommunicationTypeID ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(3)}>
              <label>
                I prefer that you send all of my communications via:&nbsp;&nbsp;
              </label>
              <select
                id="ctl00_Holder_Request01_CommPref"
                name="ctl00$Holder$Request01$commpref"
                style={{ width: '8em' }}
                value={state.PreferCommunicationTypeID}
                onChange={handlePreferCommunicationTypeIDChange}
              >
                <option value="" />
                {state.PreferCommunicationTypeList.map(type => <option key={type.Name} value={type.Value}>{type.Name}</option>)}
              </select>
              <br />
              <span className="notation">
                (Note: Postal Mail will be sent to the address specified in
                Step 1)
              </span>
            </div>
            <div className="errorMsgs">
              {Object.keys(state.Error).map(x => <span key={state.Error[x]}>{state.Error[x]}<br /></span>)}
            </div>
            <p className="clear">&nbsp;</p>
            <div id="button" className="floatleft">
              <Link to="/New_Leave_Request_step2/">« Previous Step</Link>
            </div>
            <input
              type="submit"
              id="setp1submit"
              value="Continue &raquo;"
              style={{ cursor: 'pointer' }}
            />
          </form>
        </div>
      </Layout>
    </PrivateRoute>
  )
}
